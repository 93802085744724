<template>
  <CCard>
    <CCardHeader class="bg-dark text-white">
      Cloud Storage Usage
    </CCardHeader>
    <CCardBody class="bg-white">
      <CloudStorageChartBox />
    </CCardBody>
  </CCard>
</template>

<script>
import CloudStorageChartBox from "./CloudStorageChartBox";

export default {
  name: "CloudStorageChart",
  components: {
    CloudStorageChartBox,
  },
};
</script>
