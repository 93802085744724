<template>
  <!-- <div
    class="card stat-widget-box"
    :class="color"
    :style="{ backgroundImage: `url(${backgroundUrl})` }"
  > -->
  <!-- <div
    class="card stat-widget-box"
    :class="color"
    :style="{ backgroundImage: `url(${backgroundUrl})` }"
  > -->
   <div
    class="card stat-widget-box"
    :style="{'background-color':getRandomColor(),backgroundImage: `url(${backgroundUrl})`}"
    
  >
  <!-- {{color}} -->
    <div class="card-body text-center pt-4">
      <div class="row box-row">
        <div class="col-7">
          <!-- {{data}} -->
          <h5>{{ header }}</h5>
        </div>
        <div class="col-5">
          <h1 style="color:white;">{{ text }}</h1>
        </div>
      </div>
      <h6 style="margin-top:15px;text-align:'left';color:white;">{{ footer }}</h6>

      <slot></slot>
    </div>
  </div>
</template>

<script>
import backgroundUrl from "@/assets/images/stat-box-bg.png";
export default {
  data() {
    return {
      backgroundUrl,
    };
  },
  name: "StatWidgetBox",
  methods:{
    getRandomColor(){
      var letters = '0123456789ABCDEF';
      var color = '#';
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    }
  },
  props: {
    data: String,
    header: String,
    text: String,
    footer:String,
    color: String,
  },
};
</script>

<style scoped>
.stat-widget-box h5,
.stat-widget-box h1 {
  text-align: left;
  color: #fff;
}
.stat-widget-box .box-row {
  align-items: flex-end;
}

.stat-widget-box h1 {
  margin-bottom: 3px;
}

.stat-widget-box {
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: right;
}
</style>
